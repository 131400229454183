import React, { useState } from 'react';
import { instanceOpinion } from '../utils/api';
import starsy  from '../Components/img/whitestar.png';


function Subops({setOpinions}) {
  const [name, setName] = useState('');
  const [opinion, setOpinion] = useState('');
  const [rate, setRate] = useState(0);


  const handleSubmit = async () => {
    try {

      if(name&&rate){
        const response = await instanceOpinion.post('/', {
          name,
          opinion,
          rate,
        });
        if (response.status === 200) {
         
          setName('');
          setOpinion('');
          setRate(0);
          const fetchData = async () => {
            try { 
              const response = await instanceOpinion.get('/allopinions');
              if (response.status === 200) {
                const Ops = response.data;
                setOpinions(Ops);
              }
            } catch (error) {
              alert("No se pudieron cargar las opiniones")
             
            } 
          };
          fetchData();
  
  
  
  
        } else {
          console.log('Failed to submit opinion');
        }
      }else{
        alert("Nombre y calificacion son requeridos")
      }

    } catch (error) {
      alert("Tu opinion ya ha sido publicada")
      
    }
  };


  

  return (
    <div className='submitopinion'>
      <div>
      <div className='subopdiv'>
      <h4>Nombre:</h4>   
      </div>
      <div className='subopdiv'>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      </div>
      <div>
      <div className='subopdiv'>
      <h4>Opinión:</h4>
      </div>
      <div className='subopdiv'>
          <textarea id="opinion" value={opinion} onChange={(e) => setOpinion(e.target.value)}></textarea>
      </div>
      </div>

      <div>
      <div className='subopdiv'>   
      <h4>Calificación:</h4>
      </div>
      <div className='subopdiv'> 
<div >
<button className='starwhite' onClick={() => setRate(1)}>
  {rate >= 1 ? "⭐" : <img src={starsy} className='imgstarwhite' alt="empty star" />}
</button>
<button className='starwhite' onClick={() => setRate(2)}>
  {rate >= 2 ? "⭐" : <img src={starsy} className='imgstarwhite' alt="empty star" />}
</button>
<button className='starwhite' onClick={() => setRate(3)}>
  {rate >= 3 ? "⭐" : <img src={starsy} className='imgstarwhite' alt="empty star" />}
</button>
<button className='starwhite' onClick={() => setRate(4)}>
  {rate >= 4 ? "⭐" : <img src={starsy} className='imgstarwhite' alt="empty star" />}
</button>
<button className='starwhite' onClick={() => setRate(5)}>
  {rate >= 5 ? "⭐" : <img src={starsy} className='imgstarwhite' alt="empty star" />}
</button>
</div>
</div>
      </div>

      <div className='subopdiv'>  
      
      <button className="backbutton" onClick={handleSubmit}>Opinar</button>
      </div>
    </div>
  );
}

export default Subops;
