import { useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { Api } from '../utils/api';
import { myEnv } from '../utils/api';
// import image1 from "../Components/img/2021 Twitter logo - blue.png"
// import image2 from "../Components/img/f_logo_RGB-Blue_58.png"


function Footer({
  isMount,
  isModalOpen,
  openModal,
  closeModal,
  forgotPassword,
  setForgotPassword,
  setMount,
  setIsModalOpen,
  forgotFalse,
  forgotTrue,
  signup,
  setSignup
}) {
  
  const navigate = useNavigate();
  const [loginDataUser, setLoginDataUser] = useState({ email: '', password: ''});
  const [signUpDataUser, setSignUpDataUser] = useState({ email: '', password: '', username:''});
  const [myEmail, setMyEmail]=useState('')
  const [isLoggined, setIsLoggined] = useState(false);
  
  const handleLoginDataUser = (event) => {
    const { name, value } = event.target;
    setLoginDataUser({ ...loginDataUser, [name]: value });
  };
  const handleSignUpDataUser = (event) => {
    const { name, value } = event.target;
    setSignUpDataUser({ ...signUpDataUser, [name]: value });
  };
  const handleEmailChange = (event) => {
   
    setMyEmail(event.target.value);
  };

  const handleLogout = async () => {
    try {
      const response = await Api.post('/logout');
   
      if (response) {
        
        document.location.replace('/');
      } else {
        alert('Failed to log out.');
      }
    } catch (error) {
      throw error;
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsModalOpen(false);
    try {
      const response = await Api.post('/login',loginDataUser)
    
      if (response.status === 200) {
        const data = await response.data;
        setIsLoggined(true);
        setMount(true);
        const number = data.mynumber;
        localStorage.setItem('access', number);
        navigate('/admin');
      } else {
        alert('Failed to login.');
      }
    } catch (error) {
      alert('Failed to login.');
    }
  };

  const signUpChange = () => {
    if(signup===null||signup===''){
    setSignup('go')
  }else{
    setSignup(null)
  }
  }
  const handleSignUp = async (e) => {
    e.preventDefault();
    setIsModalOpen(false);
    try {
      const response = await Api.post('/',signUpDataUser)
      
      if (response.status === 200) {
        const data = await response.data;
        setIsLoggined(true);
        setMount(true);
        const number = data.mynumber;
        localStorage.setItem('access', number);
        navigate('/admin');
      } else {
        alert('Failed to SignUp.');
      }
    } catch (error) {
      alert('Failed to SignUp.');
    }
  };

  
  const handleSendEmail = async (e) => {
    e.preventDefault();
    let emailData = {
      email: myEmail
    };
  
    try {
      await Api.post('/send-email', emailData);
      alert("If you are a user, you will receive an email with a new password")
      setMyEmail('')
    } catch (error) {
      alert('Failed to send email.');
    }
    setForgotPassword(false);  // move this line outside the try-catch block.
  };
  if (myEnv) {
    return(
      <div className='footer' style={{ background:'Black', zIndex: 0, position:"relative"}}>
      <div className='flexdivfooter'>
      <div className='divfollows'>
        <div className='footerfollows'>
        <h3 className='ftitle'>Siguenos</h3>
        </div>
        {/* <div >
        <a  href="https://twitter.com/TwitterDev"
        data-size="large">
      <img style={{width:"30px"}} alt='twitter logo' src={image1}></img></a>
              <a  href="https://facebook.com"
        data-size="large">
      <img style={{width:"30px", marginLeft:"15px"}} alt='facebook logo' src={image2}></img></a>
              </div> */}
        
      </div>
      <div className='divfooter'>
        <div>
        <h3 className='ctitle'>Contacto</h3>
        </div>
        <div>
        <h4>Correo:</h4>
        <h5>contactoabogadasaj@gmail.com</h5>
          <h4>Telefono:</h4>
          <h5>(57)3154320686</h5>
          <h5>(57)3174305329</h5>
         
        </div>
      </div>
      <div>
      {isLoggined && isMount === true ? (
        <div>
          <button className="bsession" style={{ cursor: 'pointer' }} onClick={handleLogout}>
            Cerrar sesion
          </button>
        </div>
      ) : (
        <div>
          <button className="bsession" style={{ cursor: 'pointer' }} onClick={openModal}>
            Iniciar sesion
          </button>
        </div>
      )}
      </div>  
      </div>
  
  
      <div  className='createdbylink'>
        <a style={{ cursor: 'pointer', color:"green"}} href="https://www.javiermp.com"><h6>Created by JMP</h6></a>
        </div>
    
      <Modal
        isOpen={isModalOpen}
        contentLabel="Login Modal"
          style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex :2500
          },
          content: {
            width: '200px',
            height: '250px',
            margin: 'auto',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
            borderRadius: '5px',
            zIndex :2500
          },
        }}
      >
        {signup===null && forgotPassword===false?(
          <div>
          <h2>Iniciar session</h2>
          <form onSubmit={handleLogin}>
            <div>
              <input
                type="email"
                placeholder="name@example.com"
                name="email"
                onChange={handleLoginDataUser}
                value={loginDataUser.email}
              />
            </div>
            <div>
              <input
                type="password"
                className="form-control"
                placeholder="password"
                name="password"
                onChange={handleLoginDataUser}
                value={loginDataUser.password}
              />
            </div>
            <button className='bresetpass' style={{ cursor: 'pointer' }} onClick={forgotTrue}>Recuperar clave</button>
            <button className='sessionstart'type="submit">Iniciar Sesion</button>
  
  
          </form>
          <button className='createuser' style={{ cursor: 'pointer' }} onClick={signUpChange}>Crear cuenta</button>
        </div>
        ) : (<div>
        </div>
        )}
  {signup===null && forgotPassword===true?(<div>
          <h2>Ingresa tu correo</h2>
          <form onSubmit={handleSendEmail}>
            <div>
              <input
                type="email"
                placeholder="name@example.com"
                name="email"
                onChange={handleEmailChange}
                value={myEmail}
              />
            </div>
            <button className='passreset' style={{ cursor: 'pointer' }} type="submit">Recuperar clave</button>
          </form>
          <button className='sessionclose' style={{ cursor: 'pointer' }} onClick={forgotFalse}>Volver</button>
  
        </div>
        ) : (<div>
          
          </div>
  
          )}
       
       {signup && 
          <div>
                        <h2>Crear cuenta</h2>
          <form onSubmit={handleSignUp}>
            <div>
              <input
                type="name"
                placeholder="username"
                name="username"
                onChange={handleSignUpDataUser}
                value={signUpDataUser.username}
              />
            </div>
            <div>
              <input
                type="email"
                placeholder="name@example.com"
                name="email"
                onChange={handleSignUpDataUser}
                value={signUpDataUser.email}
              />
            </div>
            <div>
              <input
                type="password"
                className="form-control"
                placeholder="password"
                name="password"
                onChange={handleSignUpDataUser}
                value={signUpDataUser.password}
              />
            </div>
            <button className='bresetpass' style={{ cursor: 'pointer' }} onClick={signUpChange}>Iniciar Sesion</button>
            <button className='sessionstart'type="submit">Crear cuenta</button>
          </form>
          </div>}
  
      
        <button style={{ cursor: 'pointer' }} onClick={closeModal}>
          Cerrar
        </button>
      </Modal>
    </div>
    )
  }else{
    return (
  
      <div className='footer' style={{ background:'Black', zIndex: 0, position:"relative"}}>
        <div className='flexdivfooter'>
          <div className='divfooter'>
          <div>
          <h3 className='ctitle'>Contacto</h3>
          </div>
          <div>
          <h4>Correo:</h4>
          <h5>contactoabogadasaj@gmail.com</h5>
            <h4>Telefono:</h4>
            <h5>(57)3154320686</h5>
            <h5>(57)3174305329</h5>
           
          </div>
        </div>
        <div>
        {isLoggined && isMount === true ? (
          <div>
            <button className="bsession" style={{ cursor: 'pointer' }} onClick={handleLogout}>
              Cerrar sesion
            </button>
          </div>
        ) : (
          <div>
            <button className="bsession" style={{ cursor: 'pointer' }} onClick={openModal}>
              Iniciar sesion
            </button>
          </div>
        )}
        </div>  
        </div>
  
  
        <div className='createdbylink'>
          <a style={{ cursor: 'pointer', color:"green"}} href="https://www.javiermp.com"><h6>Created by JMP</h6></a>
          </div>
      
        <Modal
          isOpen={isModalOpen}
          contentLabel="Login Modal"
            style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex :2500
            },
            content: {
              width: '200px',
              height: '250px',
              margin: 'auto',
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px',
              borderRadius: '5px',
              zIndex :2500
            },
          }}
        >
          {forgotPassword===false?(
            <div>
            <h2>Iniciar session</h2>
            <form onSubmit={handleLogin}>
              <div>
                <input
                  type="email"
                  placeholder="name@example.com"
                  name="email"
                  onChange={handleLoginDataUser}
                  value={loginDataUser.email}
                />
              </div>
              <div>
                <input
                  type="password"
                  className="form-control"
                  placeholder="password"
                  name="password"
                  onChange={handleLoginDataUser}
                  value={loginDataUser.password}
                />
              </div>
              <button className='bresetpass' style={{ cursor: 'pointer' }} onClick={forgotTrue}>Recuperar clave</button>
              <button className='sessionstart'type="submit">Iniciar Sesion</button>
            </form>
          </div>
          ) : (<div>
          </div>
          )}
  {forgotPassword===true?(<div>
            <h2>Ingresa tu correo</h2>
            <form onSubmit={handleSendEmail}>
              <div>
                <input
                  type="email"
                  placeholder="name@example.com"
                  name="email"
                  onChange={handleEmailChange}
                  value={myEmail}
                />
              </div>
              <button className='passreset' style={{ cursor: 'pointer' }} type="submit">Recuperar clave</button>
            </form>
            <button className='sessionclose' style={{ cursor: 'pointer' }} onClick={forgotFalse}>Volver</button>
  
          </div>
          ) : (<div>
            
            </div>
    
            )}        
          <button style={{ cursor: 'pointer' }} onClick={closeModal}>
            Cerrar
          </button>
        </Modal>
      </div>
    );
  }


}

export default Footer