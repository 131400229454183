import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { instanceSingle, instanceApi } from '../utils/api';

function Appconfirm() {
  // Extract appointment string from URL parameters
  const { appointmentstr } = useParams();

  // Set initial state
  const [appointment, setAppointment] = useState(null);
  const [isLoadingapp, setIsLoadingapp] = useState(true);
  const [isError, setIsError] = useState(false);
  const [showerror, setShowerror] = useState("");
  const [confirm, setConfirm] = useState("");
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  // Fetch appointment data when component mounts
  useEffect(() => {
    const fetchTimes = async () => {
      try {
        const response = await instanceSingle.get(`/${appointmentstr}`);
        if (!response.data) {
          setIsError(true);
          setShowerror(response.data.message)
        } else {
          setAppointment(response.data);
          setConfirm(response.data.paymentRef)
        }
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoadingapp(false);
      }
    };
    fetchTimes();
  }, [appointmentstr]);

  // Function to handle appointment confirmation
  const handleConfirm = async () => {
    try {
      const response = await instanceApi.put(`/appconfirm/${appointmentstr}`);
      if(response) {
        setSubmitButtonDisabled(true);
        alert("Tu cita ha sido confirmada.Gracias por elegirnos");
      }else{
        setIsError(true);
    
      }
    } catch (error) {
      setIsError(true);
      setShowerror(error.response.data.message)
    
    }
  };
  const handleCancel = async () => {
    try {
      const response = await instanceApi.put(`/appcancel/${appointmentstr}`);
      if(response) {
        setSubmitButtonDisabled(true);
        alert("Tu cita se ha cancelado");
      }
    } catch (error) {
      setIsError(true);
      
    }
  };

  // Render different views based on state
  return (
    <div style={{ background:'White', padding: "15px 15px", minHeight:"100vh"}}>
      {isLoadingapp ? (
        <div className='teamdivimg'>
          <div>Loading</div>
        </div>
      ) : isError ? (
        <div>
          <div className='teamdivimg'>
          <RouterLink to={`/`}>
            <button style={{margin:"10px 5px", cursor: 'pointer'}} className='backbutton'>Volver</button>
          </RouterLink>
          </div>
          <div className='teamdivimg'>
            <div>Error: Pagina no encontrada - {showerror}</div>
          </div>
        </div>
      ) : confirm === null ? (
        <div>
          <div className='teamdivimg'>
          <RouterLink to={`/`}>
            <button style={{margin:"10px 5px", cursor: 'pointer'}} className='backbutton'>Volver</button>
          </RouterLink>
          </div>
          <div className='teamdivimg'>
            <div>
              <p>Nombre: {appointment.name}</p>
              <p>Fecha cita: {appointment.dateofappointment}</p>
              <p>Hora: {appointment.datehourinitial}-{appointment.datehourfinal}</p>
              <p>Tramite: {appointment.subject}</p>
              <p>Valor cita: ${appointment.servicefee} COP</p>
              <div>
              <div style={{paddingTop:"15px"}} className='teamdivimg'>
              <button onClick={handleConfirm} style={{margin:"0px 5px", cursor: 'pointer'}} disabled={submitButtonDisabled}>Confirmar</button>
              <button onClick={handleCancel} style={{margin:"0px 5px", cursor: 'pointer'}} disabled={submitButtonDisabled}>Cancelar</button>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className='teamdivimg'>
          <RouterLink to={`/`}>
            <button style={{margin:"10px 0px", cursor: 'pointer'}} className='backbutton'>Volver</button>
          </RouterLink>
          </div>
          <div className='teamdivimg'>
          <div style={{margin:"10px 5px", cursor: 'pointer'}}>Tu cita ya ha sido confirmada</div>
          </div>
          <div className='teamdivimg'>
          
          <div>
              <p>Nombre: {appointment.name}</p>
              <p>Fecha cita: {appointment.dateofappointment}</p>
              <p>Hora: {appointment.datehourinitial}-{appointment.datehourfinal}</p>
              <p>Tramite: {appointment.subject}</p>
              <p>Valor cita: ${appointment.servicefee} COP</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Appconfirm;


