import React, { useState, useEffect } from 'react';
import { instancePass } from '../utils/api';
import validatePassword from '../utils/passvalidation';

const ChangePassword = ({Mynumber,setIsLoading,setIsLoggined, setMount}) => {
  const [myEmail, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [Msgp, setMsgp] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePassword(password)) {
      alert('Password must contain at least one number, one uppercase letter, one lowercase letter, and one special character.');
      return;
    }

    try {
      const response = await instancePass.post('/newpassword', {
        email: myEmail,
        password: password
      },  {
        headers: {
          'my-number': Mynumber,
        },
      });
      
      if (response.status === 200) {
        setPassword('')
        alert('Password changed successfully');
      } else {
        alert('Failed to change password');
      }
      
    } catch (err) {
     ;
      let Messagep =(err.response.data)
      setMsgp(`Error:${Messagep}`);
    }
  };

  useEffect(() => {
    if (Msgp==='Session timeout') {


      const interval = setInterval(() => {
        setMount(false);
        setIsLoading(false)
        setIsLoggined(false)
        
        
      
      }, 3 * 1000); // Trigger timeout after 20 minutes
  
      return () => clearInterval(interval);
   
    }
  }, [Msgp, setIsLoggined, setIsLoading, setMount]);

  return (
    <div>
      <div className='centerdiv'>
      <h4>Ingresa nueva clave, debe contener mas de 10 caracteres incluido numeros, letras minusculas, mayusculas y caracteres especiales</h4>
      </div>
      <div className='centerdiv'>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="email"
            placeholder="name@example.com"
            name="email"
            onChange={handleEmailChange}
            value={myEmail}
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Enter new password"
            name="password"
            onChange={handlePasswordChange}
            value={password}
          />
        </div>
        {Msgp && <p className="error-message">{Msgp}</p>}
        <button style={{ cursor: 'pointer', margin:'10px 35px' }} type="submit">Cambiar clave</button>
      </form>
      </div>
 
    </div>
  );
};

export default ChangePassword;

