import React, { useState } from 'react';
import Canonical from '../Components/Canonical'
import imagejc from '../Components/img/IMG-20230714-WA0001.jpg'
import imageas from '../Components/img/IMG-20230729-WA0000.jpg'
import imageqs from "../Components/img/quienes somos text.png"
import { Link as RouterLink } from 'react-router-dom';
import '../index.css';

function Team() {
  const [direction] = useState(Math.random() < 0.5 ? 'up' : 'down');

  return (
    <div style={{ background: 'White', padding: "15px 15px", minHeight: "100vh" }}>
      <Canonical url="/equipo" />

      <div className='divsectionheaders'>
        <div className='sectionheaders'>
          <img src={imageqs} style={{ width: "100%" }} alt="text:quienes somos"></img>
        </div>
      </div>

      <div className='flexdivall'>
        <RouterLink to={`/`}>
          <button style={{ cursor: 'pointer' }} className='backbutton'>Volver</button>
        </RouterLink>
      </div>

      {direction === "up" ? (
        <div className='teamdiv'>
          
          <div div className='team'>
          <div className='teamdivtext'>
            <h1 style={{textAlign:"center"}}>Angie Liceth Solarte Mondragon</h1>
          </div>
          <div className='teamdivimg'>
              <img src={imageas} className='imgteam' alt='Angie Liceth Solarte' />
            </div>
          <div className='teamdivtext'>
            <p>
            Mi trayectoria es de más de diecisiete 
              años de experiencia en el ámbito del Derecho 
              Notarial y Registral, asesorando y representando 
              jurídicamente a mis clientes, egresada de la Universidad 
              Santiago de Cali, con diplomado en Derecho Notarial y Registral, 
              exfuncionaria del departamento Jurídico del Instituto de Seguros 
              Sociales, exfuncionaria de la Notaria Veintidós del Círculo de Cali 
              y actualmente funcionaria de la Notaria Once del Círculo de Cali, 
              he ejercido el cargo de control de legalidad, asesora jurídica y 
              coordinadora de escrituración. Me caracterizo por mi responsabilidad y 
              eficiencia en el proceso o tramite de cada uno de mis clientes, con el 
              objetivo de obtener resultados exitosos en sus procesos.
            </p>
          </div>
          </div>
    
          <div div className='team'>
          <div className='teamdivtext'>
            <h1 style={{textAlign:"center"}}>Leisby Johanna Ospina Ruiz</h1>
            </div>
            <div className='teamdivimg'>
              <img src={imagejc} className='imgteam' alt='johanna Ospina' />
            </div>
            <div className='teamdivtext'>
            <p>
            Abogada, egresada de la Universidad Santiago de 
            Cali, con Diplomado en Derecho Urbano y Notariado y 
            Registro, actualmente funcionaria de la Notaría Once de Cali, 
            con más de 14 años de experiencia en trámites notariales, 
            realizando estudio de documentos, control de legalidad, y 
            así transmitir total confianza, brindando asesoría a mis 
            clientes en todos sus procesos con la finalidad de culminar 
            con éxito cada una de sus diligencias, Mi trabajo está basado 
            en la Honestidad, que va de la mano con la responsabilidad  y 
            eficacia. 
            </p>
            </div>
          </div>
        </div>
      ) : (
        <div className='teamdiv'>
<div className='team'>
          <div className='teamdivtext'>
            <h1 style={{textAlign:"center"}}>Leisby Johanna Ospina Ruiz</h1>
            </div>
            <div className='teamdivimg'>
              <img src={imagejc} className='imgteam' alt='johanna Ospina' />
            </div>
            <div className='teamdivtext'>
            <p>
            Abogada, egresada de la Universidad Santiago de 
            Cali, con Diplomado en Derecho Urbano y Notariado y 
            Registro, actualmente funcionaria de la Notaría Once de Cali, 
            con más de 14 años de experiencia en trámites notariales, 
            realizando estudio de documentos, control de legalidad, y 
            así transmitir total confianza, brindando asesoría a mis 
            clientes en todos sus procesos con la finalidad de culminar 
            con éxito cada una de sus diligencias, Mi trabajo está basado 
            en la Honestidad, que va de la mano con la responsabilidad  y 
            eficacia. 
            </p>
            </div>
          </div>
          <div div className='team'>
          <div className='teamdivtext'>
            <h1 style={{textAlign:"center"}}>Angie Liceth Solarte Mondragon</h1>
          </div>
          <div className='teamdivimg'>
              <img src={imageas} className='imgteam' alt='Angie Liceth Solarte' />
            </div>
          <div className='teamdivtext'>
            <p>
            Mi trayectoria es de más de diecisiete 
              años de experiencia en el ámbito del Derecho 
              Notarial y Registral, asesorando y representando 
              jurídicamente a mis clientes, egresada de la Universidad 
              Santiago de Cali, con diplomado en Derecho Notarial y Registral, 
              exfuncionaria del departamento Jurídico del Instituto de Seguros 
              Sociales, exfuncionaria de la Notaria Veintidós del Círculo de Cali 
              y actualmente funcionaria de la Notaria Once del Círculo de Cali, 
              he ejercido el cargo de control de legalidad, asesora jurídica y 
              coordinadora de escrituración. Me caracterizo por mi responsabilidad y 
              eficiencia en el proceso o tramite de cada uno de mis clientes, con el 
              objetivo de obtener resultados exitosos en sus procesos.
            </p>
          </div>
          </div>

        </div>
      )}
    </div>
  );
}

export default Team;