import React, { useState, useEffect } from 'react';
import Appointm from "../Components/Appointm"
// import Consulta from "../Components/Consulta"
import { Link as ScrollLink, Element } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import { instancePublish } from '../utils/api';
import { instanceClient } from '../utils/api';
import imageqs from "../Components/img/quienes somos text.png"
import imagews from "../Components/img/Digital_Glyph_Green.png"
import imageop from "../Components/img/opinion.png"
import imagetn from "../Components/img/tramites  text.png"
import imageac from "../Components/img/agenda text.png"
import imageconsulta from "../Components/img/consulta text.png"
import imageti from "../Components/img/temas.png"
import Canonical from '../Components/Canonical'
import Opinions from '../Components/Opinions'
import Subops from '../Components/Subops'
import { instanceOpinion } from '../utils/api';

function Home({

  setPublish
}) {

const [text, setText] = useState(false)
const [classmore, setTextmore] = useState("read-more-less");

const [publishText1, setPublishText1]=useState(''); 
const [publishText2, setPublishText2]=useState('');
const [publishText3, setPublishText3]=useState('');

const [myfees, setmyFee]=useState('');
const [opinions, setOpinions] = useState([]);
const [countVisits, setCountVisits] = useState('');

useEffect(() => {
  const fetchData = async () => {
    try { 
      const response = await instanceOpinion.get('/allopinions');
      if (response.status === 200) {
        const Ops = response.data;
        setOpinions(Ops);
       
      } else {
        alert('Error fetching opinions');
      }
    } catch (error) {
      alert("No se pudieron cargar las opiniones")
    } 
  };
  fetchData();
}, []);


useEffect(() => {
  const fetchData1 = async () => {
    try { 
      const response = await instanceClient.get('/visitscount');
      if (response.status === 200) {
        const countmyVisits = response.data.count;
        setCountVisits(countmyVisits);
        
      } 
    } catch (error) {
      console.log('no visits available');
    } 
  };
  fetchData1();
}, []);

useEffect(() => {
  const fetchPublish = async () => {
    try {
      const response = await instancePublish.get('/publishes');
      
      setPublishText1(response.data.partition1);
      
      if(response.data.partition2){
        setPublishText2(response.data.partition2);
      }
      if(response.data.partition3){
        setPublishText3(response.data.partition3);
      }
    } catch (error) {
    console.log('no text available');
    }
  };
  fetchPublish();
}, []);


useEffect(() => {

  if(publishText1!==""){
    setPublish(publishText1)
  }

}, [setPublish, publishText1]);

useEffect(() => {
  const fetchmyFee= async () => {
    try {
      const response = await instancePublish.get('/fees');
      setmyFee(response.data.fee);
    } catch (error) {
    console.log('no fee available');
    }
  };
  fetchmyFee();
}, []);

useEffect(() => {
  if(text === false) {
    setTextmore("read-more-less");
  } else {
    setTextmore("nodisplay-more");
  }
}, [text]);

const generateMailtoLink = () => {
  const recipientEmail = 'contactoabogadasaj@gmail.com';
  const subject = encodeURIComponent('consulta/inquietud');
  const mailtoLink = `mailto:${recipientEmail}?subject=${subject}`;
  return mailtoLink;
};

const mailtoLink = generateMailtoLink();
  
return (
<div>
  <Canonical url="https://www.asesoriasaj.com/" />
  <div className='navigationlinks' style={{  background:'White', zIndex: 1000, position:"relative"}}>
    <ul className='navul'>
      <ScrollLink
      className="navbullets"
      style={{ cursor: 'pointer'}}
      to="Acerca"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      Quienes somos
      </ScrollLink>
      <ScrollLink
      className="navbullets"
      style={{ cursor: 'pointer'}}
      to="Tramites"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      Trámites
      </ScrollLink>
      <ScrollLink
      className="navbullets"
      style={{ cursor: 'pointer'}}
      to="consulta"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      Consulta
      </ScrollLink>
      <ScrollLink
      className="navbullets"
      style={{ cursor: 'pointer'}}
      to="cita"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      Citas
      </ScrollLink>
      <ScrollLink
      className="navbullets"
      style={{ cursor: 'pointer'}}
      to="Opiniones"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      Opiniones
      </ScrollLink>
      {publishText1&&(<ScrollLink
      className="navbullets"
      style={{ cursor: 'pointer'}}
      to="temas"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      Temas de interés
      </ScrollLink>)}
    </ul>
  </div>
  <div className='titlediv' style={{ background:'White', zIndex: 1000}}>
    <div className='divsectionheaders'>
      <Element name="Acerca">
        <div className='sectionheaders'>
          <img src={imageqs} alt="text:quienes somos"></img>
        </div>
      </Element>
    </div>
      <div className='divsectionheaders'>
        <h1 style={{ textAlign: "justify", margin:"15px 10px"}}>
        Bienvenidos, somos una empresa de asesoría jurídica ubicada en 
        la ciudad de Cali, Colombia. Nuestro equipo,
        compuesto por dos abogadas (<span>
        <RouterLink to={`/equipo`}>
        <div className='read-more-less'>haz clic aquí para ver más</div>
        </RouterLink>
        </span>), con la más alta
        experiencia y trayectoria, está 
        dedicado a manejar con profesionalismo y compromiso 
        todos los trámites y asuntos legales de nuestros clientes. <span>
        <button className={classmore} onClick={() => setText(!text)}>..Leer más</button>
        </span>{text && (<span>Con cada trámite que 
        gestionamos, con cada consulta que resolvemos, nos esforzamos
        por marcar una diferencia en la vida de las personas y 
        las organizaciones a las que atendemos. Así que, ya sea que necesites
        asesoría jurídica, gestión de trámites, o cualquier asunto legal, estamos 
        aquí para ayudarte. Permítenos hacer lo que mejor sabemos hacer, para que tú
        puedas centrarte en lo que más 
        importa: tu vida y tu negocio. <span>
        <button className="read-more-less" onClick={() => setText(false)}>..Leer menos</button>
        </span></span>)}
        </h1>
      </div>
    </div>
  <div className='titlediv'>
    <div className='divsectionheaders' style={{ background:'White', zIndex: 1000}}>
      <Element name="Tramites">
        <div className='sectionheaders'>
          <img src={imagetn}  alt="text:Tramites Notariales en Cali"></img>
        </div>
      </Element>
    </div>
    <div className='divuldocs background-image-ul'>
    
    <ul >
    
    <li><h1>&#x261B; Sucesiones</h1></li>
    <li><h1>&#x261B; Divorcios</h1></li>
    <li><h1>&#x261B; Testamentos</h1></li>
    <li><h1>&#x261B; Unión Marital de Hecho</h1></li>
    <li><h1>&#x261B; Liquidación de Sociedad Conyugal y Patrimonial</h1></li>
    <li><h1>&#x261B; Matrimonios</h1></li>
    <li><h1>&#x261B; Constitución de Fideicomiso Civil</h1></li>
    <li><h1>&#x261B; Correcciones de Registro Civil</h1></li>
    <li><h1>&#x261B; Compraventas de Inmuebles</h1></li>
    <li><h1>&#x261B; Donaciones</h1></li>
    <li><h1>&#x261B; Poderes Generales y Especiales</h1></li>
    <li><h1>&#x261B; Autorizaciones de Salida del País</h1></li>
    <li><h1>&#x261B; Inventario Solemne de Bienes</h1></li>
    </ul>
    </div>
  </div>
  <div className='titlediv'>
    <div className='divsectionheaders'>
      <Element name="consulta">
        <div className='sectionheaders'>
          <img src={imageconsulta} alt="text:consulta"></img>
        </div>
      </Element>
    </div>
    <div className='teamdivtext'>
      
      <p style={{wordBreak:"break-all"}}>Si tienes alguna consulta o inquietud, escríbenos a nuestro correo <a href={mailtoLink} target='_blank' rel='noreferrer'>
      contactoabogadasaj@gmail.com
        </a>. Puedes también escribirnos a nuestro whatsApp en
         dispositivos móviles dando click en el siguiente icono <a href='https://wa.me/573154320686' target='_blank' rel='noreferrer' ><img style={{width:"30px",verticalAlign:'middle'}}src={imagews} alt='whatsapp icon'></img></a></p>
    </div>
    {/* <Consulta /> */}
  </div>
  <div className='titlediv'>
    <div className='divsectionheaders'>
      <Element name="cita">
        <div className='sectionheaders'>
          <img src={imageac} alt="text:Agenda cita"></img>
        </div>
      </Element>
    </div>
    <div className='teamdivtext'>
      <p>Nuestros servicios tienen una tarifa de ${myfees} COP 
      por cada treinta minutos de servicio. Puedes agendar más 
      tiempo, sin embargo, en la mayoría de los casos, 30 minutos es 
      suficiente.</p>
    </div>
    <Appointm />
  </div>
  <div  style={publishText1 === '' ? {paddingBottom: "30px", background: "white", zIndex: 1000, position: "relative"} : {}}>
  <div className='titlediv'>
    <div className='divsectionheaders'>
      <Element name="Opiniones">
        <div className='sectionheaders'>
          <img src={imageop} alt="text:Opiniones"></img>
        </div>
      </Element>
    </div>

        <div>
          <Opinions
          opinions={opinions}
          />
        </div>
        <div className='divsectionheaders'>
        <h1 style={{ textAlign: "justify", margin:"15px 10px"}}>Tu opinión es valiosa para nosotros, califica nuestro servicio. Gracias</h1>
        </div>
        
    <div>
    <Subops
    setOpinions={setOpinions}
    />
    
    </div>
        </div>
        </div>

  {publishText1&&(
  <div style={{paddingBottom:"30px", background:"white", zIndex:1000, position:"relative"}}>
    <div className='titlediv' style={{ background:'White', zIndex: 1000}}>
      <div className='divsectionheaders'>
        <Element name="temas">
          <div className='sectionheaders'>
            <img src={imageti} alt="text:temas de interes"></img>
          </div>
        </Element>
      </div>
      <div className='divsectionheaders'>
        
        {publishText1!==''&&publishText2!==''&&publishText3!==''? (
        <h1 style={{ textAlign: "justify",margin:"15px 10px"}}>
        {publishText1}<span>
        <button className={classmore} onClick={() => setText(!text)}>..Leer más<br></br></button>
        </span>{text&&(<span>{publishText2} <br></br><br></br>{publishText3}
        <button className="read-more-less" onClick={() => setText(false)}>..Leer menos<br></br></button>
        </span>)}
        </h1>
        ) : (
        <h1 style={{ textAlign: "justify",margin:"15px 10px"}}>
        {publishText1} <span>
        <button className={classmore} onClick={() => setText(!text)}>..Leer más</button>
        </span>{text&&(<span>{publishText2}
        <button className="read-more-less" onClick={() => setText(false)}>..Leer menos<br></br></button>
        </span>)}
        </h1>
        )}
      </div>
    </div>
  </div>)}

  <div className='visitas'>
    <h3>visitas:{countVisits}</h3>
  </div>
</div>
);
}

export default Home;

