import React, { useState, useEffect } from 'react';
import { instanceAccess } from '../utils/api';
import { instancePublish } from '../utils/api';


const Fees= ({Mynumber,setIsLoading,setIsLoggined, setMount}) => {
  const [Msgp, setMsgp] = useState('');
  const [feetoset, setFee] = useState('');
  useEffect(() => {
    const fetchFee= async () => {
        try {
            const response = await instancePublish.get('/fees');
            setFee(response.data.fee);
          
        
        } catch (error) {
            console.log('no fee available');
        }
    };
    fetchFee();
}, []);

   

  const handleFee = async (e) => {
    e.preventDefault();
    try {
      const response = await instanceAccess.put('/fees', {
        myfee:feetoset
      },  {
        headers: {
          'my-number': Mynumber,
        },
      }
      );
      
      if(response.status === 200) {
        alert("Tarifa de servicio se ha actualizado")
      } else {
        alert("Error al actualizar tarifa")
      }
   } catch (err) {
      
      let Messagep =(err.response.data)
      setMsgp(`Error:${Messagep}`);
    }
  };

  useEffect(() => {
    if (Msgp==='Session timeout') {


      const interval = setInterval(() => {
        setMount(false);
        setIsLoading(false)
        setIsLoggined(false)
        
        
      
      }, 3 * 1000); // Trigger timeout after 20 minutes
  
      return () => clearInterval(interval);
   
    }
  }, [Msgp, setIsLoggined, setIsLoading, setMount]);

  return (
    <div style={{paddingBottom:"20px"}}>
       <div className='divinput'>
      
    <label style={{color:"black"}} htmlFor="tarifa">Valor cita/treinta minutos:</label>
    </div> 
    <div className='divinput'>
    <input 
    
    style={{width:"200px"}}
      id="tarifa"
      type="number"
      placeholder="Ingresa valor tarifa"
      name="tarifa"
      onChange={(e) => setFee(e.target.value)}
      value={feetoset}
    />
    </div>
 

  
        {Msgp && <p className="error-message">{Msgp}</p>}
        <div className='schedulebutton'>
        <button className="divtimesel"style={{ cursor: 'pointer' }} type="submit" onClick={handleFee}>Actualizar</button>
        </div>
    </div>
  );
};

export default Fees;

