import React, { useState } from 'react';



function Opinions({
  opinions
}) {
const [text, setText] = useState(false);


const renderStars = (rate) => {
  let stars = '';
  for (let i = 0; i < rate; i++) {
    stars += '⭐';
  }
  return stars;
}

return (
  <div>
    <div>
    { !text && opinions.length > 0 ?
    <div>
    <div className='divsectionheaders'>
    <h1 style={{ textAlign: "justify", margin:"15px 10px"}}>
          Esto es lo que opinan nuestros clientes de nuestros servicios:
        </h1>
    </div>
    </div>:""}
    { !text && opinions.length > 0 ? (
      <div>
        {opinions.length===1?
        <div className='flexdivops'>
        <div>
          <h3>{opinions[0].createdAt.split('T')[0]}</h3>
          <h3>{renderStars(opinions[0].rate)}</h3>
        
        <p>"{opinions[0].opinion}"</p>
        <h3>{opinions[0].name} </h3>
        </div>
        </div>:
        <div className='flexdivops2'>
        <div className='opdiv'>
          <h3>{opinions[0].createdAt.split('T')[0]}</h3>
          <h3>{renderStars(opinions[0].rate)}</h3>
        
        <p>"{opinions[0].opinion}"</p>
        <h3>{opinions[0].name} </h3>
        </div>
        <div className='opdiv'>
          <h3>{opinions[1].createdAt.split('T')[0]}</h3>
          <h3>{renderStars(opinions[1].rate)}</h3>
        
        <p>"{opinions[1].opinion}"</p>
        <h3>{opinions[1].name} </h3>
        </div>
        </div>}
   
        <div className='flexdivops2'>
        {opinions.length>2?<button className="backbutton" onClick={() => setText(!text)}>..Leer más</button>:""}
        </div>
        
      </div>
    ) : (
      <div>
        <div className='flexdivops2'>
        { opinions.length > 0 ? (
          opinions.map((element) => (
            <div key={element.id} className='opdiv'>
              <h3>{element.createdAt.split('T')[0]}</h3>
              
              <h3>{element.name} {renderStars(element.rate)}</h3>

              <p>"{element.opinion}"</p>
              <h3>{element.name}</h3>
             
            </div>
          ))
        ) : null }
        </div>

       <div className='flexdivops2'>
       {opinions.length>2?<button className="backbutton" onClick={() => setText(!text)}>..Leer menos</button>:""}
        </div>
      </div>
    )}
    </div>
  </div>
);
}

export default Opinions;

