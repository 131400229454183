import Home from './Pages/Home';
import Admin from './Pages/Admin';
import React, { useState } from 'react';
import Header from './Components/Header';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Footer from './Components/Footer';
import Team from './Pages/Team';
import Appconfirm from './Pages/Appconfirm';

import Modal from 'react-modal';
 
Modal.setAppElement('#root')

function App() {
  
  const[isMount, setMount]=useState(false)
  const[publish, setPublish]=useState('')
  const [forgotPassword, setForgotPassword]=useState(false)
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signup, setSignup] = useState(null)
  
 
  const openModal = () => {
    setIsModalOpen(true);
    
  };
  const forgotTrue = () => {
setForgotPassword(true)
  };

  const closeModal = () => {
    setSignup(null)
    setIsModalOpen(false);
  };
  const forgotFalse = () => {
    setSignup(null)
    setForgotPassword(false)
    
  };


  return (
<Router>
        <main>
          <Header style={{zIndex: 1000}}
          
          isMount={isMount}
          publish={publish}/>
          <div className='maincontent'> 
            <Routes> 
              <Route
                path="/" 
                element={<Home
                  setPublish={setPublish}  
                            />}         
              />
              <Route 
                path="/admin" 
                element={<Admin
                setMount={setMount} 
                           />}         
              />
              <Route 
                path="/equipo" 
                element={<Team
                           />}         
              />
              <Route 
                path="/:appointmentstr" 
                element={<Appconfirm
                           />}         
              />
                          
            </Routes>
          </div>
          <Footer      
                isMount={isMount}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                openModal={openModal}
                closeModal={closeModal}
                forgotPassword={forgotPassword} 
                setForgotPassword={setForgotPassword}
                setMount={setMount}
                forgotFalse={forgotFalse}
                forgotTrue={forgotTrue}
                signup={signup}
                setSignup={setSignup}
                />
        </main>
      </Router>
  );
}

export default App;
