import React, { useState } from 'react';
import Datesaval from './Datesaval';
import  {instanceApi} from '../utils/api'

function Appointm() {
  const [userFormData, setUserFormData] = useState({
    name: '',
    email: '',
    // phone:'',
    subject: '',
    message: '',
  });

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedITime, setSelectedITime] = useState('');
  const [selectedFTime, setSelectedFTime] = useState('');
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const handleAsuntoChange = (event) => {
    setUserFormData({ ...userFormData, subject: event.target.value });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserFormData({ ...userFormData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const dateMilliseconds = selectedDate.split('kk');
    const dateiMilliseconds = selectedITime.split('kk');
    const datefMilliseconds = selectedFTime.split('kk');
    
    try {
      const response = await instanceApi.post('/appointment', {
        ...userFormData,
        dateofappointment: dateMilliseconds[1],
        dateMill:parseInt(dateMilliseconds[0]),
        datei_appointment: parseInt(dateiMilliseconds[0]),
        dateiMilliseconds:parseInt(dateiMilliseconds[0]),
        datehourinitial: dateiMilliseconds[1],
        datef_appointment: parseInt(datefMilliseconds[0]),
        datefMilliseconds:parseInt(datefMilliseconds[0]),
        datehourfinal: datefMilliseconds[1]
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (response.status === 200) {
        setSelectedDate('');
        setSelectedFTime('');
        setSelectedITime('');
        setSubmitButtonDisabled(true);
        alert("Tu cita ha sido reservada por 10 min, revisa tu correo para confirmarla. Gracias")
      } else {
        setSelectedDate('');
        setSelectedFTime('');
        setSelectedITime('');
        setSubmitButtonDisabled(true);
        window.alert("something went wrong");
      }
    } catch (error) {
      setSelectedDate('');
      setSelectedFTime('');
      setSelectedITime('');
      setSubmitButtonDisabled(true);
  

        window.alert("something went wrong");
    
    }
  };
  

  return (
    <div className="acformdiv">
     <form className="acform" onSubmit={handleSubmit}>
    <div className='inputdiv'>
      <label className="aclabel"htmlFor="name">Nombre:</label>
      <input
        id="name"
        type="text"
        placeholder="Ingresa Nombre"
        name="name"
        onChange={handleInputChange}
        value={userFormData.name}
      />
    </div>

  <div className='inputdiv'>
    <label className="aclabel" htmlFor="email">Correo Electrónico:</label>
    <input
      id="email"
      type="text"
      placeholder="Ingresa Correo Electronico"
      name="email"
      onChange={handleInputChange}
      value={userFormData.email}
    />
  </div>

  {/* <div className='inputdiv'>
    <label className="aclabel" htmlFor="phone">Telefono:</label>
    <input
      id="phone"
      type="text"
      placeholder="Ingresa Telefono"
      name="phone"
      onChange={handleInputChange}
      value={userFormData.phone}
    />
  </div> */}

  <div className='inputdiv'>
    <label className="aclabel" htmlFor="message">Mensaje:</label>
    <textarea
    className="textareaFullWidth"
      id="message"
      rows="7"
      cols="14"
      name="message"
      placeholder="Mas detalles de tu tramite"
      onChange={handleInputChange}
      value={userFormData.message}
    />
  </div>

  <div className='inputdiv'>
    <label className="aclabel" htmlFor="Asunto">Trámite:</label>
    <select
      id="Asunto"
      value={userFormData.subject}
      onChange={handleAsuntoChange}
    >
  <option value="">Selecciona Asunto</option>
    <option value="Divorcios">Divorcios</option>
    <option value="Testamentos">Testamentos</option>
    <option value="Unión Marital de Hecho">Unión Marital de Hecho</option>
    <option value="Liquidación de Sociedad Conyugal y Patrimonial">Liquidación de Sociedad Conyugal y Patrimonial</option>
    <option value="Constitución de Fideicomiso Civil">Constitución de Fideicomiso Civil</option>
    <option value="Correcciones de Registro Civil">Correcciones de Registro Civil</option>
    <option value="Compraventas de Inmuebles">Compraventas de Inmuebles</option>
    <option value="Donaciones">Donaciones</option>
    <option value="Poderes Generales y Especiales">Poderes Generales y Especiales</option>
    <option value="Autorizaciones de Salida del País">Autorizaciones de Salida del País</option>
    <option value="Inventario Solemne de Bienes">Inventario Solemne de Bienes</option>
    <option value="Matrimonios">Matrimonios</option>
    </select>
  </div>

  {userFormData.name && userFormData.email && userFormData.subject ? (
    <div className='inputdiv'>
      <Datesaval
        selectedDate={selectedDate}
        selectedITime={selectedITime}
        selectedFTime={selectedFTime}
        setSubmitButtonDisabled={setSubmitButtonDisabled}
        submitButtonDisabled={submitButtonDisabled}
        setSelectedDate={setSelectedDate}
        setSelectedITime={setSelectedITime}
        setSelectedFTime={setSelectedFTime}
      />
    </div>
  ) : (
    <div className='inputdiv'>    
      <button type="submit" disabled={true}>
        Selecciona fecha
      </button>
    </div>
  )}
  <div className='buttdiv'>
  <button className="sentbutton"style={{ cursor: 'pointer' }} type="submit" disabled={submitButtonDisabled}>
    Programar cita
  </button>
  </div>


</form>

    </div>
  );
}

export default Appointm;


