// utils/passwordValidator.js
 function validatePassword(password) {

  const hasValidLength = password.length >= 11;
  const hasNumber = /\d/.test(password);

  // Check for uppercase letters
  const hasUppercase = /[A-Z]/.test(password);

  // Check for lowercase letters
  const hasLowercase = /[a-z]/.test(password);

  // Check for special characters (non-alphanumeric)
  const hasSpecialChar = /\W/.test(password);

  return hasValidLength && hasNumber && hasUppercase && hasLowercase && hasSpecialChar;
}


export default validatePassword



