import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { instanceAccess } from '../utils/api';
import "react-datepicker/dist/react-datepicker.css";

// Main function
const ScheduleForm = ({Mynumber,setIsLoading,setIsLoggined, setMount}) => {
  // State declarations
  const [endDate, setEndDate] = useState("");
  const [starthour, setStartHour] = useState('');
  const [endhour, setEndHour] = useState('');
  const [days, setDays] = useState([]);
  const [Msg, setMsg] = useState('');

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formattedEndDate = endDate ? `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}` : '';
    const schedule = formSchedule(days, starthour, endhour, formattedEndDate);


    try {
      const response = await instanceAccess.post(`/${schedule}`, null, { headers: { 'my-number': Mynumber } });
     if(response){
      setMsg("Nueva programacion ha sido configurada")
      alert("Configuracion exitosa")
     }
    } catch (err) {
      
      let Message =(err.response.data)
      setMsg(`Error:${Message}`);
    }
  };
  const handleCancelSchedule = async (event) => {

    try {
      const response = await instanceAccess.post(`/cancelschedule`, null, { headers: { 'my-number': Mynumber } });
     if(response){
      setMsg("Atencion a clientes cancelada")
      alert("Programacion ha sido cancelada")
     }
    } catch (err) {
     
      let Message =(err.response.data)
      setMsg(`Error:${Message}`);
    }
  };

  // useEffect to handle session timeout
  useEffect(() => {
    if (Msg==='Session timeout') {
      const interval = setInterval(() => {
        setMount(false);
        setIsLoading(false)
        setIsLoggined(false)
      }, 3 * 1000); 
      return () => clearInterval(interval);
    }
  }, [Msg, setIsLoggined, setIsLoading, setMount]);

  // Time options for schedule
  const timeOptions = Array.from({length: 24}, (v, k) => `${k}:00`);
  const filteredEndHourOptions = filterEndHours(timeOptions, starthour);

  // Days of the week for schedule
  const daysOfWeek = [
    { number: "0", name: 'Domingo' },
    { number: "1", name: 'Lunes' },
    { number: "2", name: 'Martes' },
    { number: "3", name: 'Miercoles' },
    { number: "4", name: 'Jueves' },
    { number: "5", name: 'Viernes' },
    { number: "6", name: 'Sabado' },
  ];

  return (
    <div>
       <div style={{ zIndex:4000, position:"relative"}} className='formdatescreate'>
            <form  onSubmit={handleSubmit}>
     
     <div className='notedate'>El campo "selecciona fecha" corresponde al ultimo dia de la programacion comenzando desde la fecha actual</div>
   <div>
     <ScheduleDaysSelection days={days} daysOfWeek={daysOfWeek} onDaysChange={handleDaysChange} />
     <ScheduleTimeSelection timeOptions={timeOptions} filteredEndHourOptions={filteredEndHourOptions} startHour={starthour} onStartTimeChange={setStartHour} endHour={endhour} onEndTimeChange={setEndHour} />
     <ScheduleEndDateSelection endDate={endDate} onDateChange={setEndDate} />
   </div>
   {Msg && <p className="error-message">{Msg}</p>}
   <div className='schedulebutton'>
   <button className='divtimesel' type="submit">Programar</button>
   </div>
   

   
 </form>

    </div>
    <div className='schedulebutton'>
   <button className='divtimesel' onClick={handleCancelSchedule}>Cancelar programacion</button>
   </div>
    </div>
   

  );

  function formSchedule(days, startHour, endHour, formattedEndDate) {
    const arraytopass = ["0","1","2","3","4","5","6"].filter((day) => !days.includes(day));
    let daystring = arraytopass.join('');
    return `${daystring}kk${startHour.split(':').join('qq')}kk${endHour.split(':').join('qq')}kk${formattedEndDate}`;
  }

  function handleDaysChange(dayNumber) {
    if (days.includes(dayNumber)) {
      setDays(days.filter((day) => day !== dayNumber));
    } else {
      setDays([...days, dayNumber]);
    }
  }

  function filterEndHours(timeOptions, startHour) {
    const timeFormat = 'HH:mm';
    return timeOptions.filter((time) => {
      const time1 = moment(time, timeFormat);
      const time2 = moment(startHour, timeFormat);
      const timeDiffMs = time1.diff(time2);
      return timeDiffMs > 0;
    });
  }
};

const ScheduleDaysSelection = ({days, daysOfWeek, onDaysChange}) => (
  <div className='divtimesel'>
    <label>Selecciona disponibilidad:</label>
    {daysOfWeek.map((day) => (
      <div key={day.number}>
        <input type="checkbox" value={day.number} checked={days.includes(day.number)} onChange={() => onDaysChange(day.number)} />
        <label>{day.name}</label>
      </div>
    ))}
  </div>
);

const ScheduleTimeSelection = ({timeOptions, filteredEndHourOptions, startHour, onStartTimeChange, endHour, onEndTimeChange}) => (
  <div>
    <div className='divtimesel'>
      <label>Inicio horario de atencion</label>
      <select value={startHour} onChange={(e) => onStartTimeChange(e.target.value)}>
        {timeOptions.map((time) => (
          <option key={time} value={time}>{time}</option>
        ))}
      </select>
    </div>
    <div className='divtimesel'>
      <label>Final horario de atencion: </label>
      <select value={endHour} onChange={(e) => onEndTimeChange(e.target.value)}>
        {filteredEndHourOptions.map((time) => (
          <option key={time} value={time}>{time}</option>
        ))}
      </select>
    </div>
  </div>
);

const ScheduleEndDateSelection = ({endDate, onDateChange}) => {
  const today = new Date();
  const maxDate = new Date().setDate(today.getDate() + 8);
  return (
    <div style={{position:"relative", zIndex:1000}}>
      <label>Selecciona fecha: </label>
      <DatePicker className='picker'
        selected={endDate} 
        onChange={onDateChange} 
        maxDate={new Date(maxDate)}
      />
    </div>
  );
};

export default ScheduleForm;
