import axios from 'axios';
import urls from "../urls.json" // Import dotenv correctly


let envlocal
let baseURL

if (urls.enviroment=== 'production') {
  baseURL = urls.BASE_URL_Pro;
  envlocal = false
} else if (urls.enviroment === 'development') {
  baseURL = urls.BASE_URL_Dev;
  envlocal = true
} else if (urls.enviroment=== 'test') {
  baseURL = urls.BASE_URL_Test;
  envlocal = true
} else {
  // Set a default base URL or handle the error
  // ...
}

export const instanceAccess = axios.create({
  baseURL: `${baseURL}/api/access`,
  withCredentials: true
});

export const instancePass = axios.create({
  baseURL: `${baseURL}/api/password`,
  withCredentials: true
});

export const instanceApi = axios.create({
  baseURL: `${baseURL}/api/`,
  withCredentials:true
});

export const Api = axios.create({
  baseURL: `${baseURL}/api/administrator`,
});

export const instanceClient = axios.create({
  baseURL: `${baseURL}/customer`,
  withCredentials: true
});
export const instanceSingle = axios.create({
  baseURL: `${baseURL}/singleapp`,
});
export const instancePublish = axios.create({
  baseURL: `${baseURL}/publish`,
});
export const instanceOpinion = axios.create({
  baseURL: `${baseURL}/opinion`,
  withCredentials: true
});

export const myEnv = envlocal







