import React, { useEffect, useState } from 'react';
import  { instanceClient } from '../utils/api';

// This is your main component
const Datesaval = ({
    selectedDate,
    selectedITime,
    selectedFTime,
    setSubmitButtonDisabled,
    setSelectedDate,
    setSelectedITime,
    setSelectedFTime
}) => {
    const [dates, setDates] = useState([]);
    const [datesarray, setDatesarray] = useState([]);
    const [times, setTimes] = useState([]);

    // Fetch dates when the component mounts
    useEffect(() => {
        const fetchDates = async () => {
            try {
                const response = await instanceClient.get('/datesonly');
                setDates(response.data);
            } catch (error) {
                console.log('no dates available');
            }
        };
        fetchDates();
    }, []);

    // Fetch times when the component mounts
    useEffect(() => {
        const fetchTimes = async () => {
            try {
                const response = await instanceClient.get('/dates');
                setTimes(response.data);
            } catch (error) {
                console.log('no times available');;
            }
        };
        fetchTimes();
    }, []);

    // Create a new array of dates when dates state changes
    useEffect(() => {
        const arraytomap = dates.map((d) => d);
        let arraytopush = [];
        let arrayl = 0;

        for (let index = 0; index < arraytomap.length; index++) {
            if (arrayl === 0) {
                arraytopush.push(arraytomap[index]);
                arrayl++;
            }
            if (arraytopush[arrayl-1].date !== arraytomap[index].date) {
                arraytopush.push(arraytomap[index]);
                arrayl++;
            }
        }  
        setDatesarray(arraytopush);
    }, [dates]);

    // Handlers for the select elements
    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
        setSelectedITime('');
        setSelectedFTime('');
        setSubmitButtonDisabled(true);
    };

    const handleTimeIChange = (event) => {
        setSelectedITime(event.target.value);
        setSelectedFTime('');
        setSubmitButtonDisabled(true);
    };

    const handleTimeFChange = (event) => {
        setSelectedFTime(event.target.value);
        setSubmitButtonDisabled(event.target.value === '');
    };

    // Functions for filtering available times
    const timeIAvailable = (time) => {
        const dateSelectedarray = selectedDate.split('kk');
        const dateSelected = parseInt(dateSelectedarray[0]);
        return time.date_milliseconds === dateSelected;
    };

    const timeFAvailable = (timef) => {
       
  
        const timeiCSelectedarray = selectedITime.split('kk');
        const timeiCSelected = timeiCSelectedarray[2];
        const timeiMCSelected = timeiCSelectedarray[0];
        const timeiDMCSelected = timeiCSelectedarray[6];
        const timeiCC2Selected = timeiCSelectedarray[3];
        let timeiW1CSelected = timeiCSelectedarray[4];
        let timeiW2CSelected = timeiCSelectedarray[5];


        if(timef.datef_milliseconds <= parseInt(timeiMCSelected)) {
            return false};

        if(timef.datef_milliseconds > parseInt(timeiMCSelected)+60*60*1000) {
            return false};
        if(timef.date_milliseconds !== parseInt(timeiDMCSelected)) {
            return false};
            if((timeiW1CSelected===null ||timeiW1CSelected==='')&&(timeiW2CSelected===null ||timeiW2CSelected==='') ){
                return timef.consecutivedateswt1_code === timeiCSelected || timef.consecutivedateswt2_code === timeiCC2Selected  ;
            }else if(timeiW1CSelected!==null ||timeiW1CSelected!==''){
                return timef.consecutivedateswt2_code === timeiCC2Selected;
            }else{
                return timef.consecutivedateswt1_code === timeiCSelected;
            }
        
    }

    // Return the JSX
    return (
        <div>
            <div className='inputdiv'>
                <label className="aclabel" htmlFor="date">Fecha:</label>
                <select id="date" value={selectedDate} onChange={handleDateChange}>
                    <option value="">seleccione</option>
                    {datesarray.map((date) => (
                        <option key={date.id} value={`${date.date_milliseconds}kk${date.date}`}>
                            {date.date}
                        </option>
                    ))}
                </select>
            </div>
            <div className='inputdiv'>
                <label className="aclabel" htmlFor="timei">Hora Inicio:</label>
                <select id="timei" value={selectedITime} onChange={handleTimeIChange} disabled={!selectedDate}>
                    <option value="">seleccione</option>
                    {selectedDate && times.filter(timeIAvailable).map((time) => (
                        <option key={time.id} value={`${time.datei_milliseconds}kk${time.datei}kk${time.consecutivedateswt1_code}kk${time.consecutivedateswt2_code}kk${time.time_Slot_wait1}kk${time.time_Slot_wait2}kk${time.date_milliseconds}`}>
                            {time.datei}
                        </option>
                    ))}
                </select>
                <label className="aclabel" htmlFor="timef">Hora Final:</label>
                <select id="timef" value={selectedFTime} onChange={handleTimeFChange} disabled={!selectedITime}>
                    <option value="">seleccione</option>
                    {selectedITime && times.filter(timeFAvailable).map((timef) => (
                        <option key={timef.id} value={`${timef.datef_milliseconds}kk${timef.datef}`}>
                            {timef.datef}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default Datesaval;
