import { Link } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import React from 'react';
import image1 from "./img/logoaj.png"
import imagebalance from "../Components/img/IMG-20230714-WA0000.png"

function Header({
  isMount,
  publish
}) {
  const location = useLocation();

  return (
    <div className="headerimg" style={{ position:"relative",zIndex: 2000 }}>
       
       <div className="flexdiv">
       <img src={imagebalance} alt="balance sketch" className="balance"></img>
       </div>
       <div className="flexdiv2">
       <img src={image1}  alt="logo" className="logo"></img>
       </div>
       <div className="flexdiv">
       <img src={imagebalance} alt="balance sketch" className="balance"></img>
       </div>
        
    
       {location.pathname === '/' && !isMount && (
        <div className='navlinks' style={{ zIndex: 2000, }}>
          <div className='menuswitch'><h1>&#9776;</h1></div>
          <ul className='nvul'>
        <Link
          className="nbullets"
          style={{ cursor: 'pointer', fontSize:"17px"}}
          to="Acerca"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Quienes somos
        </Link>

        <Link
          className="nbullets"
          style={{ cursor: 'pointer', fontSize:"17px"}}
          to="Tramites"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Trámites
        </Link>

        <Link
          className="nbullets"
          style={{ cursor: 'pointer', fontSize:"17px"}}
          to="consulta"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Consulta
        </Link>
        <Link
          className="nbullets"
          style={{ cursor: 'pointer', fontSize:"17px"}}
          to="cita"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Citas
        </Link>
        <Link
          className="nbullets"
          style={{ cursor: 'pointer', fontSize:"17px"}}
          to="Opiniones"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Opiniones
        </Link>
        {publish&&(<Link
          className="nbullets"
          style={{ cursor: 'pointer', fontSize:"17px"}}
          to="temas"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Temas de interés
        </Link>)}
      </ul>
    </div>
     )}
      
    </div>
  );
}

export default Header;
