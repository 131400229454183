import React, { useState, useEffect } from 'react';
import { instanceAccess } from '../utils/api';
import { instancePublish } from '../utils/api';


const Publish= ({Mynumber,setIsLoading,setIsLoggined, setMount}) => {
  const [Msgp, setMsgp] = useState('');
  const [partitionOne, setPartitionOne] = useState('');
  const [partitionTwo, setPartitionTwo] = useState('');
  const [partitionThree, setPartitionThree] = useState('');
  useEffect(() => {
    const fetchPublish = async () => {
        try {
            const response = await instancePublish.get('/publishes');
            setPartitionOne(response.data.partition1);
          
            if(response.data.partition2){
              setPartitionTwo(response.data.partition2);

            }
            if(response.data.partition3){
              setPartitionThree(response.data.partition3);

            }
        } catch (error) {
            console.log('no text available');
        }
    };
    fetchPublish();
}, []);

   

  const handlePublish = async (e) => {
    e.preventDefault();
    try {
      const response = await instanceAccess.put('/publishes', {
        partition1: partitionOne,
        partition2: partitionTwo,
        partition3: partitionThree,
      },  {
        headers: {
          'my-number': Mynumber,
        },
      }
      );
      
      if(response.status === 200) {
        
        alert("Tema de interes se ha actualizado")
      } else {
       alert("Error al actualizar tema de interes")
      }
   } catch (err) {
      
      let Messagep =(err.response.data)
      setMsgp(`Error:${Messagep}`);
    }
  };

  useEffect(() => {
    if (Msgp==='Session timeout') {


      const interval = setInterval(() => {
        setMount(false);
        setIsLoading(false)
        setIsLoggined(false)
        
        
      
      }, 3 * 1000); // Trigger timeout after 20 minutes
  
      return () => clearInterval(interval);
   
    }
  }, [Msgp, setIsLoggined, setIsLoading, setMount]);

  return (
    <div style={{paddingBottom:"20px"}}>
      <div>
      <div className='inputdiv'>
    <label className="aclabel" style={{color:"black"}}>Parte 1-leer menos:</label>
    <textarea
    className="textareaFullWidth"
      id="message"
      rows="7"
      cols="14"
      name="message1"
      placeholder='texto para publicar-seccion leer menos'
      onChange={(e) => setPartitionOne(e.target.value)}
      value={partitionOne}
      
    />
  </div>
      <div className='inputdiv'>
    <label className="aclabel" style={{color:"black"}}>Parte 2-leer mas:</label>
    <textarea
    className="textareaFullWidth"
      id="message"
      rows="7"
      cols="14"
      name="message2"
      placeholder='texto para publicar-seccion leer mas'
      onChange={(e) => setPartitionTwo(e.target.value)}
      value={partitionTwo}
      
    />
  </div>
      <div className='inputdiv'>
    <label className="aclabel" style={{color:"black"}}>Parte 3-leer mas:</label>
    <textarea
    className="textareaFullWidth"
      id="message"
      rows="7"
      cols="14"
      name="message3"
      placeholder='texto para publicar-seccion leer mas'
      onChange={(e) => setPartitionThree(e.target.value)}
      value={partitionThree}
      
    />
  </div>
        
      </div>
        {Msgp && <p className="error-message">{Msgp}</p>}
        <div className='schedulebutton'>
        <button className="divtimesel"style={{ cursor: 'pointer' }} type="submit" onClick={handlePublish}>Publicar</button>
        </div>
    </div>
  );
};

export default Publish;

