import React, { useState, useEffect } from 'react';
import { instanceAccess } from '../utils/api';
import { useNavigate } from 'react-router-dom';
import Datestocreate from '../Components/Datestocreate'
import Publish from '../Components/Publish'
import Fees from '../Components/Fees'
import Changepassword from '../Components/Changepassword'
import Canonical from '../Components/Canonical'

function Admin({setMount}) {
  const [appointments, setAppointments] = useState([]);
  const [isLoggined, setIsLoggined] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const[Mynumber,setMynumber]= useState('');
  const [showAppointments, setShowAppointments] = useState(true);
  const [showDatestocreate, setShowDatestocreate] = useState(false);
  const [showSetUpPass, setShowSetUpPass] = useState(false);
  const [showPublish, setPublish] = useState(false);
  const [showFee, settoShowFee] = useState(false);
  const toggleShowAppointments = () => setShowAppointments(!showAppointments);
  const toggleShowDatestocreate = () => setShowDatestocreate(!showDatestocreate);
  const toggleshowSetUpPass = () => setShowSetUpPass(!showSetUpPass);
  const togglePublish = () => setPublish(!showPublish);
  const toggleFee = () => settoShowFee(!showFee);

  useEffect(() => {
    const mynumber = localStorage.getItem('access');
    const fetchData = async () => {
      try {
        
        const response = await instanceAccess.get('/seeappointments', {
          headers: {
            'my-number': mynumber,
          },
        });
        if (response.status === 200) {
          const Apps = response.data;
          setIsLoggined(Apps.islogged_in);
          setMynumber(mynumber)
          setAppointments(Apps.apps);
        } else {
          alert('Error fetching appointments');
        }
      } catch (error) {
        localStorage.setItem('access','');
        setIsLoading(false)
        setIsLoggined(false)
        
      } finally {
        setIsLoading(false);
        localStorage.setItem('access','');
      }
    };


    fetchData();
  }, []);

  useEffect(() => {
    if (!isLoggined && !isLoading) {
      setMount(false)
      navigate('/');
    }
  }, [isLoggined, isLoading, navigate, setMount]);

  useEffect(() => {
    const interval = setInterval(() => {
      setMount(false);
      setIsLoading(false)
      setIsLoggined(false)
      
      
      navigate('/');
    },20*60*1000); // Trigger timeout after 20 minutes

    return () => clearInterval(interval);
  }, [navigate, setMount, setIsLoading, setIsLoggined]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    
    
 
    <div style={{ zIndex:5, position:"relative"}} className='admindiv'>
      <Canonical url="/admin" />
         <div style={{ cursor: 'pointer'}} className='headersadmin' onClick={toggleShowAppointments}>
      Citas
      </div>
       {/*Par1 */}
       {showAppointments && 
    <div className='appsdiv'>
    {appointments.map((element) => (
        <div key={element.id} className='appscont'>
          <h4>Nombre:</h4>
          <h5>{element.name}</h5>
          <h4>Correo electronico:</h4>
          <h5>{element.email}</h5>
          <h4>Fecha cita:</h4>
          <h5>{element.dateofappointment}</h5>
          <h4>Hora cita:</h4>
          <div className='timeapp'>
          <h5>{element.datehourinitial}</h5>
          <h5>-</h5>
          <h5>{element.datehourfinal}</h5>
          </div>
          <h4>Asunto:</h4>
          <h5>{element.subject}</h5>
          <h4>Mensaje:</h4>
          <h5>{element.message}</h5>
        </div>
      ))}
    </div>
       }
    {/*Par1 */}
    <div style={{ cursor: 'pointer'}} className='headersadmin' onClick={toggleShowDatestocreate}>
      Programacion Horario Atencion
      </div>
      {showDatestocreate && 
 
      <div>
        <Datestocreate 
        Mynumber={Mynumber}
        setIsLoading={setIsLoading}
        setIsLoggined={setIsLoggined}

        />
     
      </div>
      }

<div className='headersadmin' style={{ cursor: 'pointer'}} onClick={togglePublish}>
      Publicacion
      </div>
      {showPublish && <div>
        <Publish 
        Mynumber={Mynumber}
        setIsLoading={setIsLoading}
        setIsLoggined={setIsLoggined}

        />
     
      </div>
}
<div className='headersadmin' style={{ cursor: 'pointer'}} onClick={toggleFee}>
      Valor Citas
      </div>
      {showFee && <div>
        <Fees 
        Mynumber={Mynumber}
        setIsLoading={setIsLoading}
        setIsLoggined={setIsLoggined}

        />
     
      </div>
}
      <div className='headersadmin' style={{ cursor: 'pointer'}} onClick={toggleshowSetUpPass}>
      Cambiar clave
      </div>
      {showSetUpPass && <div>
        <Changepassword 
        Mynumber={Mynumber}
        setIsLoading={setIsLoading}
        setIsLoggined={setIsLoggined}

        />
     
      </div>
}
 
    </div>
    
  );
}

export default Admin;
